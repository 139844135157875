function TextWithImage () {
    return (
        <div className="bottom-container-img">
            <div className="about-company"><h4>Dlaczego drewno?</h4>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Fuga itaque doloribus eos impedit inventore labore, nemo sed excepturi eaque debitis soluta quaerat laudantium libero dignissimos. Rem cumque consectetur aperiam. Autem!
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Fuga itaque doloribus eos impedit inventore labore, nemo sed excepturi eaque debitis soluta quaerat laudantium libero dignissimos. Rem cumque consectetur aperiam. Autem!</div>
            <div className="wood-img"></div>
            <div className="about-company"><h4>Tniemy, łączymy, kleimy i znowu tniemy.</h4>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Fuga itaque doloribus eos impedit inventore labore, nemo sed excepturi eaque debitis soluta quaerat laudantium libero dignissimos. Rem cumque consectetur aperiam. Autem!
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Fuga itaque doloribus eos impedit inventore labore, nemo sed excepturi eaque debitis soluta quaerat laudantium libero dignissimos. Rem cumque consectetur aperiam. Autem!</div>
            <div className="about-company"><h4>Drewno = trwałość oraz ciepło wnętrza</h4>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Fuga itaque doloribus eos impedit inventore labore, nemo sed excepturi eaque debitis soluta quaerat laudantium libero dignissimos. Rem cumque consectetur aperiam. Autem!
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Fuga itaque doloribus eos impedit inventore labore, nemo sed excepturi eaque debitis soluta quaerat laudantium libero dignissimos. Rem cumque consectetur aperiam. Autem!</div>
            <div className="wood-img"></div>
            <div className="about-company"><h4>Prawdziwy styl NIE modny. To twój styl który Cię wyróżnia!</h4>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Fuga itaque doloribus eos impedit inventore labore, nemo sed excepturi eaque debitis soluta quaerat laudantium libero dignissimos. Rem cumque consectetur aperiam. Autem!
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Fuga itaque doloribus eos impedit inventore labore, nemo sed excepturi eaque debitis soluta quaerat laudantium libero dignissimos. Rem cumque consectetur aperiam. Autem!</div>
        </div>
    )
}
export { TextWithImage };