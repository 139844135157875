function InstaIcon () {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
  <g clipPath="url(#clip0_1_85)">
    <path d="M16.1263 5.0665H21.5263C22.3695 5.07641 23.2044 5.23437 23.993 5.53317C24.5672 5.75771 25.0888 6.09872 25.5248 6.53471C25.9608 6.97071 26.3018 7.49225 26.5263 8.0665C26.8251 8.85505 26.9831 9.68996 26.993 10.5332C27.0596 11.9332 27.0596 12.3998 27.0596 15.9332C27.0596 19.4665 27.0596 19.9332 26.993 21.3332C26.9831 22.1764 26.8251 23.0113 26.5263 23.7998C26.3018 24.3741 25.9608 24.8956 25.5248 25.3316C25.0888 25.7676 24.5672 26.1086 23.993 26.3332C23.2044 26.632 22.3695 26.7899 21.5263 26.7998H10.7263C9.8831 26.7899 9.04818 26.632 8.25963 26.3332C7.68538 26.1086 7.16384 25.7676 6.72785 25.3316C6.29185 24.8956 5.95084 24.3741 5.7263 23.7998C5.4275 23.0113 5.26954 22.1764 5.25964 21.3332C5.19297 19.9332 5.19297 19.4665 5.19297 15.9332C5.19297 12.3998 5.19297 11.9332 5.25964 10.5332C5.26954 9.68996 5.4275 8.85505 5.7263 8.0665C5.95084 7.49225 6.29185 6.97071 6.72785 6.53471C7.16384 6.09872 7.68538 5.75771 8.25963 5.53317C9.04818 5.23437 9.8831 5.07641 10.7263 5.0665H16.1263ZM16.1263 2.6665H10.6596C9.5481 2.71325 8.44857 2.91521 7.39297 3.2665C6.50351 3.64624 5.70731 4.21494 5.05964 4.93317C4.36191 5.57089 3.8348 6.37301 3.5263 7.2665C3.11656 8.30856 2.89099 9.41389 2.85964 10.5332C2.79297 11.9332 2.79297 12.3998 2.79297 15.9998C2.79297 19.5998 2.79297 20.0665 2.85964 21.4665C2.89099 22.5858 3.11656 23.6911 3.5263 24.7332C3.8348 25.6267 4.36191 26.4288 5.05964 27.0665C5.69735 27.7642 6.49948 28.2913 7.39297 28.5998C8.43503 29.0096 9.54035 29.2352 10.6596 29.2665H21.593C22.7122 29.2352 23.8176 29.0096 24.8596 28.5998C25.7531 28.2913 26.5552 27.7642 27.193 27.0665C27.8871 26.4122 28.4327 25.6165 28.793 24.7332C29.1443 23.6776 29.3462 22.578 29.393 21.4665C29.4596 20.0665 29.4596 19.5998 29.4596 15.9998C29.4596 12.3998 29.4596 11.9332 29.393 10.5332C29.3462 9.42164 29.1443 8.3221 28.793 7.2665C28.4327 6.38321 27.8871 5.58752 27.193 4.93317C26.5386 4.23904 25.7429 3.69342 24.8596 3.33317C23.804 2.98188 22.7045 2.77992 21.593 2.73317H16.1263" fill="#3D3D3D"/>
    <path d="M16.1264 9.13281C14.7683 9.13281 13.4407 9.53554 12.3115 10.2901C11.1823 11.0446 10.3022 12.117 9.78246 13.3717C9.26274 14.6264 9.12676 16.0071 9.39171 17.3391C9.65666 18.6711 10.3106 19.8946 11.271 20.8549C12.2313 21.8153 13.4548 22.4693 14.7868 22.7342C16.1188 22.9992 17.4995 22.8632 18.7542 22.3435C20.0089 21.8237 21.0813 20.9436 21.8359 19.8144C22.5904 18.6852 22.9931 17.3576 22.9931 15.9995C22.9931 14.1783 22.2697 12.4318 20.9819 11.144C19.6942 9.85626 17.9476 9.13281 16.1264 9.13281ZM16.1264 20.4661C15.243 20.4661 14.3794 20.2042 13.6449 19.7134C12.9103 19.2226 12.3378 18.525 11.9998 17.7088C11.6617 16.8926 11.5732 15.9945 11.7456 15.1281C11.9179 14.2616 12.3434 13.4657 12.968 12.8411C13.5927 12.2164 14.3886 11.791 15.255 11.6186C16.1215 11.4463 17.0196 11.5347 17.8358 11.8728C18.6519 12.2109 19.3495 12.7834 19.8403 13.5179C20.3311 14.2525 20.5931 15.1161 20.5931 15.9995C20.5931 17.1841 20.1225 18.3202 19.2848 19.1579C18.4472 19.9956 17.3111 20.4661 16.1264 20.4661Z" fill="#3D3D3D"/>
    <path d="M24.8602 8.8655C24.8602 9.18195 24.7664 9.4913 24.5906 9.75442C24.4148 10.0175 24.1649 10.2226 23.8725 10.3437C23.5801 10.4648 23.2584 10.4965 22.9481 10.4348C22.6377 10.373 22.3526 10.2206 22.1288 9.99687C21.9051 9.77311 21.7527 9.48802 21.691 9.17765C21.6292 8.86728 21.6609 8.54557 21.782 8.25321C21.9031 7.96085 22.1082 7.71096 22.3713 7.53515C22.6344 7.35934 22.9438 7.2655 23.2602 7.2655C23.6846 7.2655 24.0915 7.43407 24.3916 7.73413C24.6916 8.03419 24.8602 8.44116 24.8602 8.8655Z" fill="#3D3D3D"/>
  </g>
  <defs>
    <clipPath id="clip0_1_85">
      <rect width="32" height="32" fill="white" transform="translate(0.126953)"/>
    </clipPath>
  </defs>
</svg>
    )
}
export { InstaIcon };