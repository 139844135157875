export const headerData = [
    {
        name: 'Katalog produktów',
        link: '/product-page',
    },
    {
        name: 'O mnie',
        link: '/about-me',
    },
    {
        name: 'Kontakt',
        link: '/contact',
    },
    {
        name: 'FAQ',
        link: '/faq',
    },
    {
        name: 'Koszyk',
        link: '/shop-basket',
    },

];