import { Faq } from "../components/Faq";

export const footerData = [
//    { 
//         name: 'Regulamin',
//         link: '',
//    },
//    { 
//         name: 'Polityka prywatności',
//         link: '',
//     },
    { 
        name: 'Kontakt',
        link: '/contact',
    },
    { 
        name: 'Faq',
        link: '/faq',
    },
      
];